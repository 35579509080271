<template>
  <v-col class="pa-3">
    <v-form ref="form" v-model="isValid" @submit.prevent="handleAccept" class="pt-10">
      <h1 class="text-h1 mt-10">
      {{ $t('authorisation.invitationAccept.title').toUpperCase() }}
      </h1>
      <v-alert :type="alertType" :value="!!alertMessage.length" class="my-5" transition="fade-transition">
        {{ alertMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields" class="mt-4 mb-2">
        <KTextField v-model="email" autocomplete="username" disabled field="email" tabindex="0"/>
        <KTextField v-model="password"
                    autocomplete="new-password"
                    autofocus
                    field="password"
                    tabindex="1"
                    type="password"/>
        <KTextField v-model="passwordConfirmation"
                    autocomplete="new-password"
                    field="passwordConfirmation"
                    tabindex="2"
                    type="password"/>
      </k-field-group>
      <v-row class="mx-0 my-6" justify="end">
        <v-btn :loading="isLoading" depressed tile color="secondary" tabindex="2" type="submit">
          {{ $t('authorisation.invitationAccept.request') }}
        </v-btn>
      </v-row>
    </v-form>
  </v-col>
</template>

<script lang="js">
import { getRateLimitMinutes } from '@/application/api/util/response.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import { acceptInvitation } from '@/modules/authorisation/api/register.js';
import { mapGetters } from 'vuex';

export default {
  name: 'InvitationAccept',
  components: {
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      alertType: 'success',
      alertMessage: '',
      isLoading: false,
      email: this.$route.query.email,
      password: '',
      passwordConfirmation: '',
      isValid: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      firstError: 'error/first',
    }),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: 'home',
      });
    }

    if (!this.$route.query.email) {
      this.$router.push({
        name: 'login',
      });

      throw new Error('InvitationAccept::created no email present');
    }
  },
  methods: {
    handleAccept() {
      this.isLoading = true;
      acceptInvitation(this.email, this.$route.params.token, this.password, this.passwordConfirmation)
          .then(() => {
            this.alertType = 'success';
            this.alertMessage = this.$t('authorisation.invitationAccept.successMessage');
          })
          .catch((error) => {
            this.alertType = 'error';
            const { response } = error;
            const { status } = response;

            if (status === 429) {
              this.alertMessage = this.$t('errors.429', { minutes: getRateLimitMinutes(response) });
            } else if (status === 400) {
              this.alertMessage = this.$t('authorisation.invitationAccept.errorMessage');
            }

            this.$refs.form.validate();
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: block;
  margin:  0 auto;
}
</style>
